<template>
  <loader v-bind="{ loading }" text="Loading Users">
    <columns>
      <column class="is-9">
        <form>
          <columns>
            <column class="is-2">
              <switch-input
                classes="is-medium is-rounded"
                description="Passive users can only view information and not create or update any resources"
                :value="user.is_passive"
                @input="togglePassive">
                Passive User
              </switch-input>
            </column>
            <transition name="fade" mode="out-in">
              <column v-if="user.is_passive">
                <data-selector
                  tag-class="is-medium is-rounded"
                  classes="is-medium is-rounded"
                  delete-button-class="is-medium is-rounded is-danger"
                  description="A list of customers that this user will have access to view. If no customers are selected NO customers will be made available."
                  multiple
                  searchable
                  :items="customer_list"
                  value-key="id"
                  label-key="name"
                  :value="user.customers"
                  @input="updateCustomers">
                  Accessible Customers <span class="has-text-weight-light">(optional)</span>
                </data-selector>
              </column>
            </transition>
            <transition name="fade" mode="out-in">
              <column v-if="user.is_passive">
                <data-selector
                  tag-class="is-medium is-rounded"
                  classes="is-medium is-rounded"
                  delete-button-class="is-medium is-rounded is-danger"
                  description="A list of Customer Locations that this user will have access to view. If no locations are selected all locations will be made available."
                  multiple
                  searchable
                  :items="availableLocations"
                  value-key="id"
                  label-key="name"
                  :value="user.locations"
                  @input="updateLocations">
                  Accessible Locations <span class="has-text-weight-light">(optional)</span>
                </data-selector>
              </column>
            </transition>
          </columns>
          <columns>
            <column>
              <text-input
              classes="is-medium is-rounded"
              :value="user.name"
              @input="updateName"
              :error="$root.errors.name"
              required>
              Name
              </text-input>
            </column>
            <column>
              <text-input
                classes="is-medium is-rounded"
                :value="user.surname"
                @input="updateSurname"
                :error="$root.errors.surname"
                required>
                Surname
              </text-input>
            </column>
          </columns>
          <columns>
            <column class="is-6">
              <text-input
                classes="is-medium is-rounded"
                :value="user.profile.mobile"
                @input="updateMobile"
                :error="$root.errors.mobile">
                Mobile Number
              </text-input>
            </column>
            <column>
              <text-input classes="is-medium is-rounded" :value="user.designation" @input="updateDesignation"
                :error="$root.errors.designation">
                Designation <span class="has-text-weight-light">(optional)</span>
              </text-input>
            </column>
            <column class="is-narrow" v-if="authIsSuperUser || isAnAdmin">
              <switch-input
                :disabled="isSuperUser || user.id === authUser.id"
                classes="is-medium is-rounded"
                description="Only active users may sign in and work within the system."
                :value="user.active"
                @input="toggleStatus">
                Active
              </switch-input>
            </column>
          </columns>

          <columns v-if="!user.is_passive">
            <column>
              <permissions-builder 
                :permissions="permissions"
                :is-super-user="isSuperUser"
              />
            </column>
          </columns>
          
          <columns>
            <column>
              <notification-preferences />
            </column>
          </columns>



          <columns>
            <column>
              <submit-button
              class="is-medium is-rounded"
              :working="updating"
              @submit="updateUser">
              Save
              </submit-button>
            </column>
          </columns>
        </form>
      </column>
      <column>
        <columns>
          <column>
            <h5 class="title is-5">Reset Password</h5>
            <form>
              <password-input
                required
                v-model="reset.password"
                :error="$root.errors.password"
                classes="is-rounded is-medium">
                Password
              </password-input>
              <password-input
                required
                v-model="reset.password_confirmation"
                :error="$root.errors.password_confirmation"
                classes="is-rounded is-medium">
                Password Confirmation
              </password-input>

              <submit-button
                :working="resetting"
                @submit="resetPassword"
                class="is-small is-rounded">
                Reset Password
              </submit-button>
            </form>
          </column>
        </columns>
        <columns>
                <column>
        <h5 class="title is-5">Change Email Address</h5>
        <form>
          <p>
            Current Email: {{ user.email }}
          </p>
          <text-input
            required
            :disabled="!authIsSuperUser"
            v-model="update_email.email"
            :error="$root.errors.email"
            classes="is-rounded is-medium">
            New Email Address
          </text-input>
          <submit-button
            :disabled="!authIsSuperUser"
            :working="updating_email"
            @submit="updateEmailAddress"
            class="is-small is-rounded">
            Update Email Address
          </submit-button>
        </form>
      </column>
        </columns>
      </column>
    </columns>
  </loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { permissions as permissionBackend } from '@/api'
import PermissionsBuilder from '../partials/PermissionsBuilder.vue'
import NotificationPreferences from '../partials/NotificationPreferences.vue'
import flatMap from 'lodash/flatMap'

export default {

  components: {
    PermissionsBuilder,
    NotificationPreferences
  },

  data: () => ({
    loading: true,
    updating: false,
    resetting: false,
    permissions: [],
    updating_email: false,
    update_email: {
      email: ''
    },
    reset: {
      password: '',
      password_confirmation: ''
    }
  }),

  async created() {
    await permissionBackend.loadPermissions(({data}) => {
      this.permissions = data
    }, () => {})
    await this.$store.dispatch('customer/loadCustomerList')
    this.loading = false
  },

  methods: {
    ...mapMutations('user', [
      'updateName',
      'updateSurname',
      'updateEmail',
      'updateMobile',
      'updateBranches',
      'updateCustomers',
      'updateLocations',
      'clearUser',
      'updateTeams',
      'updateDesignation',
      'toggleAutomaticTeams',
      'togglePassive',
      'toggleActive'
    ]),
    updateUser() {
      this.updating = true
      this.$store.dispatch('user/updateUser').then(() => {
        this.$toast.success('User successfully updated')
        this.updating = false
      }).catch(() => this.updating = false)
    },
    async resetPassword() {
      if(await this.$confirm({
        title: 'Reset User Password',
        message: 'Are you sure you want to reset this users password?'
      })) {
        this.resetting = true
        this.$store.dispatch('user/resetPassword', this.reset).then(() => {
          this.resetting = false
          this.$toast.success('Password Updated')
          this.reset = {
            password: '',
            password_confirmation: ''
          }
        }).catch(() => {
          this.resetting = false
          this.$toast.error('Please try again')
        })
      }
    },
    async updateEmailAddress() {
      if (await this.$confirm({
        title: 'Update User Email Address',
        message: 'Are you sure you want to change this users email?'
      })) {
        this.updating_email = true
        this.$store.dispatch('user/changeEmailAddress', this.update_email).then(() => {
          this.updating_email = false
          this.$toast.success('Email Updated')
          this.$store.commit('user/updateEmail', this.update_email.email)
          this.update_email = {
            email: '',
          }
        }).catch(() => {
          this.updating_email = false
          this.$toast.error('Please try again')
        })
      }
    },
    toggleStatus() {
      if(this.subscription.price.plan.title === 'Free') {
        return this.$alert({
          title: 'Please Ugrade Your Account',
          message: 'Free tiers cannot reactivate additional users.'
        })
      }
      this.toggleActive()
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('billing', [
      'subscription'
    ]),
    ...mapGetters('customer', ['customer_list']),
    authUser() {
      return this.$store.getters['auth/user']
    },
    isSuperUser() {
      return this.subscription.account.user_id === this.user.id
    },
    authIsSuperUser() {
      return this.subscription.account.user_id === this.authUser.id
    },
    isAnAdmin() {
      return this.user.abilities.filter(permission => permission.name === '*').length
    },
    availableLocations() {
      return flatMap(this.user.customers, 'locations')
    }
  }

}
</script>